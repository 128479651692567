<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        For each of the following pairs of ions, write the chemical formula for the ionic compound
        that they would form. Use proper notation and place the cation first.
      </p>

      <p class="mb-3">
        a)
        <chemical-latex :content="cationA" />
        and
        <chemical-latex :content="anionA" />
      </p>
      <chemical-notation-input v-model="inputs.compoundA" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        b)
        <chemical-latex content="NH4^+" />
        and
        <chemical-latex :content="anionB" />
      </p>
      <chemical-notation-input v-model="inputs.compoundB" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        c)
        <chemical-latex :content="cationC" />
        and
        <chemical-latex :content="anionC" />
      </p>
      <chemical-notation-input v-model="inputs.compoundC" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        d)
        <chemical-latex :content="cationD" />
        and
        <chemical-latex :content="anionD" />
      </p>
      <chemical-notation-input v-model="inputs.compoundD" class="mb-7" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question214',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        compoundA: null,
        compoundB: null,
        compoundC: null,
        compoundD: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    cationA() {
      if (this.versionVariableA.value === 1) {
        return 'K^{+}';
      } else if (this.versionVariableA.value === 2) {
        return 'Li^{+}';
      } else if (this.versionVariableA.value === 3 || this.versionVariableA.value === 4) {
        return 'Na^{+}';
      } else {
        return '';
      }
    },
    anionA() {
      if (this.versionVariableA.value === 1 || this.versionVariableA.value === 2) {
        return 'O^{2-}';
      } else if (this.versionVariableA.value === 3) {
        return 'S^{2-}';
      } else if (this.versionVariableA.value === 4) {
        return 'Se^{2-}';
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    anionB() {
      if (this.versionVariableB.value === 1) {
        return 'SO4^{2-}';
      } else if (this.versionVariableB.value === 2) {
        return 'SiO3^{2-}';
      } else if (this.versionVariableB.value === 3) {
        return 'SO3^{2-}';
      } else if (this.versionVariableB.value === 4) {
        return 'CrO4^{2-}';
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    cationC() {
      if (this.versionVariableC.value === 1 || this.versionVariableC.value === 2) {
        return 'Al^{3+}';
      } else if (this.versionVariableC.value === 3) {
        return 'Fe^{3+}';
      } else if (this.versionVariableC.value === 4) {
        return 'Cr^{3+}';
      } else {
        return '';
      }
    },
    anionC() {
      if (this.versionVariableC.value === 1) {
        return 'O^{2-}';
      } else if (this.versionVariableC.value === 2 || this.versionVariableC.value === 3) {
        return 'S^{2-}';
      } else if (this.versionVariableC.value === 4) {
        return 'Se^{2-}';
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    cationD() {
      if (this.versionVariableD.value === 1) {
        return 'Ba^{2+}';
      } else if (this.versionVariableD.value === 2 || this.versionVariableD.value === 3) {
        return 'Ca^{2+}';
      } else if (this.versionVariableD.value === 4) {
        return 'Mg^{2+}';
      } else {
        return '';
      }
    },
    anionD() {
      if (this.versionVariableD.value === 1 || this.versionVariableD.value === 2) {
        return 'PO4^{3-}';
      } else if (this.versionVariableD.value === 3) {
        return 'AsO4^{3-}';
      } else if (this.versionVariableD.value === 4) {
        return 'N^{3-}';
      } else {
        return '';
      }
    },
  },
};
</script>
